<template>
    <jgp-div
        _row="['*','30']"
        _runit="px"
        :id="_comId"
        :class="__class"
        v-show="__show"
        :style="{ width: width, height: height }"
    >
        <jgp-panel slot="1-1" class="wrap" _force_scroll="false" ref="xpanel">
            <jgp-div _row="['36','*']" _runit="px" class="x-scroll-wrap">
                <div slot="1-1" class="head">
                    <div class="column check-wrap" v-show="hasCheck">
                        <div class="category">
                            <label class="check">
                                <input
                                    :disabled="isSingle"
                                    type="checkbox"
                                    v-model="selectAll"
                                />
                                <span class="radio-bg"></span>
                            </label>
                        </div>
                    </div>
                    <div class="column index-wrap" v-show="hasIndex">
                        <div class="category"><span>序号</span></div>
                    </div>
                    <div
                        :class="`column field-${index}`"
                        :key="index"
                        v-for="(cat, index) in getFieldCategory"
                        @click="sortRecord(cat)"
                    >
                        <div class="category">
                            <span
                                >{{ cat.title
                                }}<i
                                    style="vertical-align: initial"
                                    :class="`fa fa-sort-${sorts[cat.name]}`"
                                ></i
                            ></span>
                        </div>
                    </div>
                    <div class="column operator-column" v-show="hasTools">
                        <div class="category"><span>操作</span></div>
                    </div>
                </div>
                <jgp-panel
                    slot="2-1"
                    class="grid"
                    _force_scroll="true"
                    ref="content"
                >
                    <div
                        class="grid-row clearfix"
                        :record-index="index"
                        v-if="fouceRefresh"
                        :key="index"
                        v-for="(record, index) in records"
                        :draggable="isDraggable"
                        @dragstart="doDragStart(index)"
                    >
                        <div class="column check-wrap" v-show="hasCheck">
                            <label class="check">
                                <input
                                    type="checkbox"
                                    :checked="selected.indexOf(index) != -1"
                                    @click.stop="doSelect(index)"
                                />
                                <span class="radio-bg"></span>
                            </label>
                        </div>
                        <div class="column index-wrap" v-show="hasIndex">
                            <span>{{ index + 1 }} </span>
                        </div>
                        <div
                            :class="`column field-${cat_index}`"
                            :key="record.__hash + cat_index"
                            v-for="(cat, cat_index) in getFieldCategory"
                            @dblclick="doRowDblClick(index, cat_index, cat)"
                            @click.stop="doRowClick(index)"
                        >
                            <jgp-grid-col
                                @onColClick="doColClick"
                                :_name="cat.name"
                                :_row_no="index"
                                :_col_no="cat_index"
                                :_args="
                                    Object.assign(
                                        {},
                                        needRenderFields[cat.name]
                                    )
                                "
                                :_value="getDisplayValue(cat, record)"
                                :_display_record="getDisplayRecord(record)"
                                :_record="record"
                            >
                            </jgp-grid-col>
                        </div>
                        <div class="column operator-column" v-show="hasTools">
                            <div class="operator-wrap">
                                <a
                                    class="grid-tool"
                                    href="javascript:void(0);"
                                    draggable="false"
                                    v-tooltip="{
                                        classes: ['grid-tip-info'],
                                        content: tool.tip,
                                        trigger: 'hover'
                                    }"
                                    @click.stop="operateRecord(tool.fn, index)"
                                    :key="tIndex"
                                    v-for="(tool, tIndex) in getRecordTools"
                                >
                                    <span
                                        ><i
                                            v-if="!!tool.icon"
                                            :class="`fa fa-${tool.icon}`"
                                        ></i
                                        >{{
                                            showToolName ? tool.tip : ''
                                        }}</span
                                    >
                                </a>
                            </div>
                        </div>
                    </div>
                </jgp-panel>
            </jgp-div>
        </jgp-panel>
        <jgp-pager
            slot="2-1"
            v-if="!!gdata && !!gdata.pager"
            class="pager"
            :_pager="gdata.pager"
            @onChange="goPage"
        >
        </jgp-pager>
    </jgp-div>
</template>

<script>
/**
 * 表格组件
 * @module ./components/grid/jgp-grid
 * @example  <jgp-grid _url="/sys/menu/ajax/queryMenu" _page_size="15"></jgp-grid>
 */
import Check from 'check-types'
import { isNumber } from 'lodash'
import Common from '../../utils/common'
export default {
    data() {
        return {
            query: null,
            selected: [],
            selectAll: false,
            gridCols: [],
            needRenderFields: {},
            loadCount: 0,
            fouceRefresh: true,
            sorts: {},
            records: []
        }
    },
    /**
     * @prop {String} _url 用于获取数据的后台地址
     * @prop {String} _ajax_param 用于向后台传递数据 对象字符串 {key1:value1,key2:value2}
     * @prop {String} _unique 设置唯一属性 {默认:'id'}
     * @prop {String} _page 用于向后台传输分页信息 当前页码
     * @prop {String} _page_size 用于向后台传输分页信息 每页记录个数
     * @prop {String} _gdata 列表数据
     * @prop {String} _alias 数据别名 即后台变量名
     * @prop {String} _single 单选
     * @prop {String} _check 是否显示选择框
     * @prop {String} _tools_name 是否显示工具名称
     * @prop {String} _index 是否显示序号
     * @prop {String} _cunit 列宽单位 {默认:'px'} 可选 px %
     * @prop {String} _cols 列宽集合 例如：_cols="['10','*','*','*']" 若列宽单位为px '*' 自适应内容的宽度，若为% '*' 平分剩余宽度
     * @prop {String} _c_max_width 最大列宽 {默认:_cunit='px' _cmaxwidth='200';_cunit='%' _cmaxwidth='30'}
     * @prop {String} _draggable 是否可拓展
     * @prop {String} _record_tools 操作列中的工具集 对象字符串数组 [{'icon':'edit','tip':'编辑','fn':'edit'},{'icon':'window-restore','fn':'addFields'}]，icon 为图标 同按钮中的icon，tip为提示信息，fn同按钮中的fn
     * @prop {String} _onclick 行点击事件 同钮组件中fn
     * @prop {String} _ondblclick 行双击事件 同钮组件中fn
     * @prop {String} _onload 数据加载完毕
     *
     */
    props: {
        _url: String,
        _ajax_param: String,
        _page: String,
        _page_size: String,
        _gdata: {
            type: Object
        },
        _alias: {
            type: String,
            default: 'gdata'
        },
        _unique: {
            type: String,
            default: 'id'
        },
        _record_tools: String,
        _cols: String,
        _c_max_width: String,
        _cunit: {
            type: String,
            default: 'px'
        },
        _single: String,
        _index: {
            type: String,
            default: 'true'
        },
        _check: {
            type: String,
            default: 'true'
        },
        _tools_name: {
            type: String,
            default: 'true'
        },
        _draggable: {
            type: String,
            default: 'false'
        },
        _onclick: String | Function,
        _oncheck: String | Function,
        _ondblclick: String | Function,
        _onload: String | Function,
        /**
         * 排序方式 client 为前端当页排序 server 为服务端排序
         */
        _sort_type: {
            type: String,
            default: 'client' // server || client
        },
        /**
         * 当_sort_type为 server 时，点击排序的回调方法，会将排序字段和排序标识返回
         */
        _onsort: String | Function
    },
    computed: {
        isSingle() {
            return Common.toBool(this.single)
        },
        hasCheck() {
            return Common.toBool(this.check)
        },
        showToolName() {
            return Common.toBool(this.tools_name)
        },
        hasIndex() {
            return Common.toBool(this.index)
        },
        hasTools() {
            return this.getRecordTools && this.getRecordTools.length > 0
        },
        isDraggable() {
            return Common.toBool(this.draggable)
        },
        getFieldCategory() {
            let newCategory = []
            if (!this.gdata) return newCategory
            for (let cat of this.gdata.cats) {
                let newCat = {}
                newCat['name'] = cat.name
                newCat['title'] = cat.title

                if (cat.mapperName) {
                    newCat['hasMapper'] = true
                    let values = this.mapperValues(cat)
                    newCat['mapperValues'] = this.mapperValues(cat)
                        ? values
                        : {}
                }
                newCategory.push(newCat)
            }
            return newCategory
        },
        getRecordTools() {
            return Common.toJson(this.record_tools)
        },
        getCols() {
            return Common.toJson(this.cols)
        },
        getCUnit() {
            return Common.trim(this.cunit)
        },
        getCMaxWidth() {
            return this.c_max_width
                ? Number(this.c_max_width) + this.getCUnit
                : /* prettier-ignore */ this.getCUnit === '%' ? '30%' : '200px'
        },
        getDataCount() {
            return this.gdata ? this.gdata.records.length : 0
        }
    },
    watch: {
        selectAll(flag) {
            if (this.isSingle) return // 如果是单选，不做任何操作
            this.selected = []
            if (flag) {
                for (let n = 0; n < this.getDataCount; n++) {
                    this.selected.push(n)
                }
            }
        },
        records() {
            if (this.onload) {
                this.$nextTick(() => {
                    Common.doFn(this.onload, this)
                })
            }
        }
    },
    methods: {
        sortRecord(cat) {
            if (this.sorts[cat.name]) {
                if (this.sorts[cat.name] === 'down') {
                    this.sorts[cat.name] = 'up'
                } else {
                    delete this.sorts[cat.name]
                }
            } else {
                this.sorts[cat.name] = 'down'
            }
            this.$nextTick(() => {
                if (this.sort_type === 'client') {
                    this.__initRecords()
                } else if (this.sort_type === 'server') {
                    Common.doFn(this.onsort, {
                        sorts: this.sorts
                    })
                }
                this.refresh()
            })
        },
        refresh() {
            this.fouceRefresh = false
            this.$nextTick(() => {
                this.fouceRefresh = true
            })
        },
        /**
         * @name load
         * @function
         * @param url {String} 数据源地址
         * @param param {Object} 键值对,用于向数据源地址传参
         * @desc 加载数据
         */
        load(url, param = {}, callback, errorCallback) {
            const _this = this
            Common.post(
                url,
                param,
                (result) => {
                    _this.loadFromQuery(result.data)
                    _this.$forceUpdate()
                    if (callback) callback(result)
                },
                errorCallback
            )
        },
        /**
         * @name setGdata
         * @function
         * @param gdata 列表数据
         * @desc 设置列表数据
         */
        setGdata(gdata) {
            this.$set(this, 'selectAll', false)
            this.$set(this, 'selected', this.selected)
            this.$set(this, 'gdata', gdata)
            this.__initRecords()
            this.$forceUpdate()
        },
        addRecord(record) {
            const _this = this
            var fields = []
            for (let key in record) {
                fields.push({
                    name: key,
                    value: record[key]
                })
            }
            if (this.gdata && this.gdata.records) {
                this.gdata.records.push({
                    fields: fields
                })
            } else {
                this.$set(this, 'gdata', { records: [{ fields: fields }] })
            }

            _this.$nextTick(() => {
                _this.__initRecords()
                _this.refresh()
            })
        },
        setRecordValue(index, key, value) {
            let gdata = Object.assign({}, this.gdata)
            gdata.records[index].fields.forEach((field) => {
                if (field.name === key) {
                    field.value = value
                }
            })
            this.$set(this, 'gdata', gdata)
            this.__initRecords()
        },
        loadSelf(pager) {
            let param = Common.toJson(this.ajax_param)
            if (!param) param = {}
            let page = pager ? pager.current : parseInt(this.page)
            param['pager.current'] = page || 1
            let pageSize = pager ? pager.pageSize : parseInt(this.page_size)
            param['pager.pageSize'] = pageSize || 15
            this.load(this.url, param)
        },
        loadFromQuery(data) {
            let gdata
            if (
                data[this.alias] &&
                Check.string(data[this.alias]) &&
                data[this.alias] !== ''
            ) {
                gdata = JSON.parse(data[this.alias])
            }
            let selected = []
            if (data.selectedKeys) {
                for (let recordKey of data.selectedKeys) {
                    gdata.records.forEach((record, index) => {
                        if (record.dataMap[this.unique] === recordKey) {
                            if (!this.selected.includes(index)) {
                                selected.push(index)
                            }
                        }
                    })
                }
            }

            this.$set(this, 'selectAll', false)
            this.$set(this, 'selected', selected)
            this.$set(this, 'gdata', gdata)
            this.__initRecords()
        },
        plusLoadCount() {
            this.loadCount++
        },
        minusLoadCount() {
            this.loadCount--
        },
        getDisplayValue(cat, record) {
            var key = cat.name
            var value = Object.assign({}, record.dataMap)
            var displayValue = cat.mapperValues
            if (key.indexOf('.') !== -1) {
                var arr = key.split('.')
                arr.forEach((k) => {
                    if (!!value && JSON.stringify(value) !== '{}') {
                        if (
                            !!cat.hasMapper &&
                            !!displayValue &&
                            JSON.stringify(displayValue) !== '{}'
                        ) {
                            const disv = displayValue[value[k]]
                            value = disv || value[k]
                        } else {
                            value = value[k]
                        }
                    }
                })
            } else {
                if (!!value && JSON.stringify(value) !== '{}') {
                    if (
                        !!cat.hasMapper &&
                        !!displayValue &&
                        JSON.stringify(displayValue) !== '{}'
                    ) {
                        let disv = ''
                        if (
                            !!value[key] &&
                            !Check.number(value[key]) &&
                            value[key].indexOf(',') !== -1
                        ) {
                            const vls = value[key].split(',')
                            for (let vl of vls) {
                                disv += displayValue[vl] + ','
                            }
                            disv = disv.substring(0, disv.length - 1)
                        } else {
                            disv = displayValue[value[key]]
                        }
                        value = disv || value[key]
                    } else {
                        value = value[key]
                    }
                }
            }
            return value
        },
        getValue(field) {
            return field ? field.value : ''
        },
        calcWidth() {
            let checkMaxWidth = 0
            if (this.hasCheck) {
                let headCheckCol = this.$el.querySelector('.head .check-wrap')
                let gridCheckCols =
                    this.$el.querySelectorAll('.grid .check-wrap')
                checkMaxWidth = this.colMaxWidth(headCheckCol, gridCheckCols)
                gridCheckCols.forEach((col) => {
                    col.style.width = checkMaxWidth + 'px'
                })
                headCheckCol.style.width = checkMaxWidth + 'px'
            }
            let indexMaxWidth = 0
            if (this.hasIndex) {
                let headIndexCol = this.$el.querySelector('.head .index-wrap')
                let gridIndexCols =
                    this.$el.querySelectorAll('.grid .index-wrap')
                indexMaxWidth = this.colMaxWidth(headIndexCol, gridIndexCols)
                gridIndexCols.forEach((col) => {
                    col.style.width = indexMaxWidth + 'px'
                })
                headIndexCol.style.width = indexMaxWidth + 'px'
            }
            let operatorMaxWidth = 0

            if (this.hasTools) {
                let headOperatorCol = this.$el.querySelector(
                    '.head .operator-column'
                )
                let gridOperatorCols = this.$el.querySelectorAll(
                    '.grid .operator-column'
                )
                operatorMaxWidth = this.colMaxWidth(
                    headOperatorCol,
                    gridOperatorCols
                )
                ;[].slice.call(gridOperatorCols).forEach((col) => {
                    col.style.width = operatorMaxWidth + 'px'
                })
                headOperatorCol.style.width = operatorMaxWidth + 'px'
            }

            let sumWidth = checkMaxWidth + indexMaxWidth + operatorMaxWidth

            const fieldCategoryCount = this.getFieldCategory.length
            const cols = this.getCols
            let colArr = []
            let perWidthFlag =
                this.getCUnit === '%' || (cols && cols.indexOf('*') !== -1)

            if (perWidthFlag) {
                let colPlaceHolderCount = 0
                let colWidthSum = 0
                cols.forEach((c) => {
                    if (Common.trim(c) === '*') {
                        colPlaceHolderCount++
                    } else {
                        colWidthSum += Number(Common.trim(c))
                    }
                })

                const placeHolderWidth = `calc((100% - ${sumWidth}px - ${colWidthSum}${this.getCUnit}  - ${cols.length}px) / ${colPlaceHolderCount})`

                cols.forEach((c) => {
                    if (Common.trim(c) === '*') {
                        colArr.push(placeHolderWidth)
                    } else {
                        colArr.push(c + this.getCUnit)
                    }
                })
            }
            for (let i = 0; i < fieldCategoryCount; i++) {
                let headFieldCol = this.$el.querySelector('.head .field-' + i)
                let gridFieldCols = this.$el.querySelectorAll(
                    '.grid .field-' + i
                )
                let fieldMaxWidth
                if (perWidthFlag) {
                    fieldMaxWidth = colArr[i]
                        ? colArr[i]
                        : this.colMaxWidth(headFieldCol, gridFieldCols)
                } else {
                    fieldMaxWidth =
                        cols && Check.number(Number(cols[i]))
                            ? Number(cols[i])
                            : this.colMaxWidth(headFieldCol, gridFieldCols)
                    sumWidth += fieldMaxWidth
                    fieldMaxWidth += 'px'
                }
                gridFieldCols.forEach((col) => {
                    col.style.width = fieldMaxWidth
                    // col.style.maxWidth = this.getCMaxWidth;
                })
                headFieldCol.style.width = fieldMaxWidth
                // headFieldCol.style.maxWidth = this.getCMaxWidth;
            }

            if (perWidthFlag) {
                sumWidth = '100%'
            } else {
                sumWidth += 'px'
            }

            this.$el.querySelector('.grid').style.width = sumWidth
            this.$el.querySelector('.head').style.width = sumWidth
            this.$el.querySelector('.x-scroll-wrap').style.width = sumWidth

            if (this.$refs.xpanel) {
                this.$nextTick(() => {
                    this.$refs.xpanel.refreshScroll()
                    console.log('调用：this.$refs.xpanel.refreshScroll')
                })
            }
        },
        colMaxWidth(headCol, gridCols) {
            let widthArr = []
            gridCols.forEach((node) => {
                widthArr.push(
                    Number(getComputedStyle(node).width.replace('px', ''))
                )
            })
            let headWidth = Number(
                getComputedStyle(headCol).width.replace('px', '')
            )
            widthArr = widthArr.sort((a, b) => (a < b ? 1 : -1))
            return widthArr[0] >= headWidth ? widthArr[0] : headWidth
        },
        operateRecord(fn, row_index) {
            const _this = this
            const record = _this.getRecord(row_index)
            Common.doFn(
                fn,
                {
                    record: record,
                    displayRecord: _this.getDisplayRecord(
                        _this.records[row_index]
                    )
                },
                window
            )
        },
        goPage(page) {
            if (this.query) {
                this.query.goPage(page)
            } else if (this.url) {
                this.loadSelf(page)
            }
        },
        doSelect(row_index) {
            const _this = this
            if (_this.isSingle) _this.selected = []
            let checked = false
            if (_this.selected.indexOf(row_index) !== -1) {
                _this.selected.splice(_this.selected.indexOf(row_index), 1)
            } else {
                _this.selected.push(row_index)
                checked = true
            }
            if (_this.oncheck) {
                const record = _this.getRecord(row_index)
                Common.doFn(_this.oncheck, {
                    checked: checked,
                    record: record,
                    displayRecord: _this.getDisplayRecord(
                        _this.records[row_index]
                    )
                })
            }
        },
        doRowDblClick(row_index, cat_index, cat) {
            const _this = this
            if (_this.ondblclick) {
                const record = _this.getRecord(row_index)
                Common.doFn(_this.ondblclick, {
                    record: record,
                    displayRecord: this.getDisplayRecord(
                        _this.records[row_index]
                    )
                })
            }
        },
        doRowClick(row_index) {
            const _this = this
            ;[].slice
                .call(_this.$el.querySelectorAll('[record-index]'))
                .forEach((row) => {
                    row.setAttribute('click-state', false)
                })

            _this.$el
                .querySelector(`[record-index="${row_index}"]`)
                .setAttribute('click-state', true)
            if (_this.onclick) {
                const record = _this.getRecord(row_index)
                Common.doFn(_this.onclick, {
                    record: record,
                    displayRecord: _this.getDisplayRecord(
                        _this.records[row_index]
                    )
                })
            }
        },
        doDragStart(index) {
            let record = this.getRecord(index)
            event.dataTransfer.setData('DRAG_DROP_DATA', JSON.stringify(record))
        },
        mapperValues(cat) {
            const name = cat.mapperName
            const relationName = cat.relationName
            if (cat.displayValues) {
                let values = {}
                for (let value of cat.displayValues) {
                    values[value[relationName]] = value[name]
                }
                return values
            }
        },
        getDisplayRecord(record) {
            const _this = this
            let result = {}
            _this.getFieldCategory.forEach((cat) => {
                result[cat.name] = _this.getDisplayValue(cat, record)
            })
            return result
        },
        /**
         * @function getRecord
         * @param index {Number} 必填 行号
         * @desc 获取指定行号的记录
         * @return record
         */
        getRecord(index) {
            let record = Object.assign({}, this.records[index])
            for (let rec in record.dataMap) {
                if (record.dataMap[rec] === '') {
                    record.dataMap[rec] = null
                }
            }
            return record.dataMap
        },
        /**
         * @name getSelected
         * @function
         * @desc 获取被勾选的记录只有在_check="true" 时才有效
         * @return [{record},{record}]
         */
        getSelected() {
            if (this.selectAll && this.selected.length === this.getDataCount) {
                return this.records.map((x) => x.dataMap)
            } else {
                let result = []
                for (let index of this.selected) {
                    result.push(this.getRecord(index))
                }
                return result
            }
        },
        /**
         * @name setSelected
         * @function
         * @param selectedKeys 传一个key 或 多个key _unique默认为'id'，则selectedKeys 为id数组
         * @desc 设置选中数据
         */
        setSelected(selectedKeys) {
            if (selectedKeys) {
                for (let recordKey of selectedKeys) {
                    this.records.forEach((record, index) => {
                        if (record.dataMap[this.unique] === recordKey) {
                            if (!this.selected.includes(index)) {
                                this.selected.push(index)
                            }
                        }
                    })
                }
            }
        },
        /**
         * @name getFocusRecords
         * @function
         * @desc 获取有焦点项
         */
        getFocusRecords() {
            let records = []
            ;[].slice
                .call(this.$el.querySelectorAll('[click-state=true]'))
                .forEach((row) => {
                    records.push(
                        this.getRecord(row.getAttribute('record-index'))
                    )
                })
            return records
        },
        /**
         * @name clearSelected
         * @function
         * @desc 清除选中项
         */
        clearSelected() {
            this.selected = []
        },

        /**
         * @name getAllRecords
         * @function
         * @desc 获取列表中所有记录
         * @return [{record},{record}]
         */
        getAllRecords() {
            let result = []
            let records = Object.assign([], this.records)
            for (let rec of records) {
                for (let key in rec.dataMap) {
                    if (rec.dataMap[key] === '&nbsp;') {
                        rec.dataMap[key] = null
                    }
                }
                result.push(rec.dataMap)
            }
            return result
        },
        __initRecords() {
            let records = []
            if (!this.gdata) return records
            for (let r of this.gdata.records) {
                let record = {
                    dataMap: {},
                    fieldMap: {}
                }
                for (let field of r.fields) {
                    record.dataMap[field.name] = Common.checkInputValue(
                        field.value
                    )
                        ? field.value
                        : ''
                    record.fieldMap[field.name] = field
                }
                record['__hash'] = this.__hash(JSON.stringify(record))
                records.push(record)
            }
            const keys = Object.keys(this.sorts)
            for (let key of keys) {
                records = records.sort((a, b) => {
                    const val1 = a.dataMap[key]
                    const val2 = b.dataMap[key]
                    if (val1 && val1) {
                        if (isNumber(val1)) {
                            return this.sorts[key] === 'up'
                                ? val1 < val2
                                : val2 >= val1
                        } else {
                            return this.sorts[key] === 'up'
                                ? val1.localeCompare(val2)
                                : val2.localeCompare(val1)
                        }
                    } else {
                        return 0
                    }
                })
            }
            this.records = records
            this.$nextTick(() => {
                const content = this.$refs['content']
                const targetView =
                    content.$el.querySelector('[record-index="0"]')
                content.scrollIntoView(targetView)
            })
        },
        __hash(str) {
            let hash = 1315423911
            let i, ch
            for (i = str.length - 1; i >= 0; i--) {
                ch = str.charCodeAt(i)
                hash ^= (hash << 5) + ch + (hash >> 2)
            }
            return hash & 0x7fffffff
        },
        doColClick(params) {
            let cat = this.getFieldCategory[params.args.colNo]
            let record = this.records[params.args.rowNo]
            params.args['displayRecord'] = this.getDisplayRecord(record)
            params.args['record'] = record.dataMap
            params.args['value'] = record.dataMap[cat.name]
            params.args['displayValue'] = this.getDisplayValue(cat, record)
            Common.doFn(params.fn, params.args)
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {
        // console.log(this.$slots.title[0].componentOptions.tag)
    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {},
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {},
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
        if (this.url) {
            this.loadSelf()
        }
        if (this.$slots.default) {
            this.$slots.default.forEach((tpl) => {
                if (
                    tpl.componentOptions &&
                    tpl.componentOptions.tag === 'jgp-grid-col'
                ) {
                    let name = tpl.componentOptions.propsData._name
                    let props = tpl.componentOptions.propsData
                    this.needRenderFields[name] = props
                }
            })
        }
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {},
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {
        this.$nextTick(() => {
            this.calcWidth()
        })
    },
    /* keep-alive 组件激活时调用。 */
    activated() {},
    /* keep-alive 组件停用时调用。 */
    deactivated() {},
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {},
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {}
}
</script>
